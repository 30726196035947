<template>
    <app-wrapper>
        <template v-slot:child>
            <!--Balance sheet -->
            <div class="w-90 center">
                <!--        &lt; Inventory-->
                <router-link :to="{ name: 'Reports' }" class="pv4-l pv3 mini-spacing"> &lt; Reports </router-link>

                <div class="flex justify-between items-center pb4 box-border-bottom">
                    <div>
                        <p class="f3 b">Balance Sheet</p>
                    </div>
                    <div>
                        <button>Update</button>
                    </div>
                </div>
            </div>
            <!--Balance sheet -->

            <!-- date, comparison & filter  -->
            <div class="w-90 center pt4">
                <div class="flex justify-between">
                    <div></div>
                    <div class="flex items-center flex-wrap justify-end items-center w-100 pt0-l pt3" style="gap: 1rem;">
                        <div class="flex flex-wrap" style="gap: 1rem;">
                            <template v-if="state.comparison === 'period1'">
                                <!-- <input v-model="state.startDate1" type="date" name="" class="input-style" disabled /> -->
                                <input
                                    v-model="state.endDate1"
                                    type="date"
                                    name=""
                                    class="input-style"
                                    style="position:fixed"
                                    disabled
                                />
                            </template>

                            <!-- <template v-else>
                                <input v-model="state.startDate2" type="date" name="" class="input-style" />
                                <input v-model="state.endDate2" type="date" name="" class="input-style" />
                            </template> -->
                        </div>

                        <!-- <select v-model="state.comparison" name="comparison" id="">
                            <option value="period1">Comparison: 1 period</option>
                            <option value="period2">Comparison: 2 period</option>
                        </select> -->

                        <!-- <button class="filter card-box" @click.prevent="handleFilter">
                            <img src="../../assets/images/filter_list.svg" alt="filter icon" />
                            <span class="pl2 w-50 tl black">Filter</span>
                        </button> -->
                    </div>
                </div>
            </div>
            <!-- date, comparison & filter  -->

            <template v-if="isLoading">
                <Spinner />
            </template>
            <template v-else>
                <div class="w-90 center mt4">
                    <!-- Business Details -->
                    <div class="pa3 box-border">
                        <div
                            class="bgTop"
                            :class="$store.state.Alert?.showMobileMenu && 'unZIndex'"
                        >
                            <OrgData heading="Balance Sheet" />

                            <!-- statement of profit/lose -->
                            <div class="tc pv4">
                                <div class="b" style="color: #132c8c">
                                    Statement of Balance Sheet for {{ orgData?.org }} as at Date
                                </div>
                            </div>
                            <div class="bb b--black-20" style="font-weight: 700; margin-bottom: -12px; font-size: 14px">Accounts</div>
                        </div>

                        <div class="scrollmenu w-100 center table-overflow">
                            <table class="w-100 tl mt3 p2" cellspacing="0">
                                <thead>
                                    <!-- <tr>
                                        <th class="bb b--black-20 tl items-center">
                                            Accounts
                                        </th>
                                        <th class="bb b--black-20 tl pr3"></th>
                                    </tr> -->
                                </thead>
                                <tbody>
                                    <tr class="font-w1 box-border-bottom">
                                        <td
                                            @click="toggleSubTable('currAsset')"
                                            class="pv3 bb b--black-20 tl font-w1 flex items-center"
                                            style="padding-right: 4rem; cursor:pointer"
                                        >
                                            <img
                                                id=""
                                                class="showLayers"
                                                :class="currAsset ? 'rotate' : ''"
                                                :src="require('@/assets/images/arrow.png')"
                                                width="5"
                                            />
                                            <div class="ml2 text-capitalize" style="font-size:13px;">Current Assets</div>
                                        </td>
                                        <td class="pv3 pr4 bb b--black-20 tl items-center"></td>
                                    </tr>
                                    <tr v-if="currAsset" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl3 bb b--black-20 font13 text-capitalize" style="padding-top:15px; padding-bottom:15px;">
                                            Cash on hand
                                        </td>
                                        <td class="bb b--black-20 textRight pr4" style="padding-top:15px; padding-bottom:15px;">
                                            {{ formatAmount(currentAssets?.cashOnHand, $store.state.Settings?.currency) }}
                                        </td>
                                    </tr>
                                    <tr v-if="currAsset" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl3 bb b--black-20 font13 text-capitalize" style="padding-top:15px; padding-bottom:15px;">
                                            Cash at Bank
                                        </td>
                                        <td class="bb b--black-20 textRight pr4" style="padding-top:15px; padding-bottom:15px;">
                                            {{ formatAmount(currentAssets?.cashAtBank, $store.state.Settings?.currency) }}
                                        </td>
                                    </tr>
                                    <tr v-if="currAsset" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl3 bb b--black-20 font13 text-capitalize" style="padding-top:15px; padding-bottom:15px;">
                                            Inventories
                                        </td>
                                        <td class="bb b--black-20 textRight pr4" style="padding-top:15px; padding-bottom:15px;">
                                            {{ formatAmount(currentAssets?.inventories, $store.state.Settings?.currency) }}
                                        </td>
                                    </tr>

                                    <tr v-if="currAsset" class="font-w1 box-border-bottom ">
                                        <td
                                            @click="toggleSubTable('outstandingRec')"
                                            class="pv3 bb b--black-20 tl font-w1 flex pl3 items-center"
                                            style="padding-right: 4rem; cursor:pointer"
                                        >
                                            <img
                                                id=""
                                                class="showLayers"
                                                :class="outstandingRec ? 'rotate' : ''"
                                                :src="require('@/assets/images/arrow.png')"
                                                width="5"
                                            />
                                            <div class="ml2 text-capitalize" style="font-size:13px;">Outstanding Receivables</div>
                                        </td>
                                        <td class="pv3 pr4 bb b--black-20 tl items-center textRight">
                                            {{
                                                formatAmount(
                                                    currentAssets?.outstandingReceivables?.totalOutstandingReceivables,
                                                    $store.state.Settings?.currency
                                                )
                                            }}
                                        </td>
                                    </tr>
                                    <tr v-if="outstandingRec" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl4 bb b--black-20 font13 text-capitalize" style="padding-top:15px; padding-bottom:15px;">
                                            Sales Credit
                                        </td>
                                        <td class="bb b--black-20 textRight pr4" style="padding-top:15px; padding-bottom:15px;">
                                            {{
                                                formatAmount(
                                                    currentAssets?.outstandingReceivables?.salesCredit,
                                                    $store.state.Settings?.currency
                                                )
                                            }}
                                        </td>
                                    </tr>
                                    <tr v-if="outstandingRec" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl4 bb b--black-20 font13 text-capitalize" style="padding-top:15px; padding-bottom:15px;">
                                            Loans Given Out
                                        </td>
                                        <td class="bb b--black-20 textRight pr4" style="padding-top:15px; padding-bottom:15px;">
                                            {{
                                                formatAmount(
                                                    currentAssets?.outstandingReceivables?.loanGivenOut,
                                                    $store.state.Settings?.currency
                                                )
                                            }}
                                        </td>
                                    </tr>
                                    <tr v-if="outstandingRec" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl4 bb b--black-20 font13 text-capitalize" style="padding-top:15px; padding-bottom:15px;">
                                            Settlement/Repayment
                                        </td>
                                        <td class="bb b--black-20 textRight pr4" style="padding-top:15px; padding-bottom:15px;">
                                            ({{
                                                formatAmount(
                                                    currentAssets?.outstandingReceivables?.settlementAndRepayment,
                                                    $store.state.Settings?.currency
                                                )
                                            }})
                                        </td>
                                    </tr>
                                    <tr v-if="outstandingRec" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl4 bb b--black-20 font13 text-capitalize" style="padding-top:15px; padding-bottom:15px;">
                                            Bad Debt
                                        </td>
                                        <td class="bb b--black-20 textRight pr4" style="padding-top:15px; padding-bottom:15px;">
                                            ({{
                                                formatAmount(
                                                    currentAssets?.outstandingReceivables?.badDebt,
                                                    $store.state.Settings?.currency
                                                )
                                            }})
                                        </td>
                                    </tr>
                                    <tr v-if="outstandingRec" class="font-w1 box-border-bottom ">
                                        <td
                                            class="pv3 bb b--black-20 tl font-w1 flex pl3 items-center"
                                            style="padding-right: 4rem; cursor:pointer"
                                        >
                                            <div class="ml2" style="font-size:13px;">Prepayment</div>
                                        </td>
                                        <td class="pv3 pr4 bb b--black-20 tl items-center textRight pr4">
                                            {{ formatAmount(currentAssets?.prepayment?.total, $store.state.Settings?.currency) }}
                                        </td>
                                    </tr>
                                    <tr class="font-w1 box-border-bottom ">
                                        <td
                                            class="pv3 bb b--black-20 tl font-w1 flex items-center backgroundF3"
                                            style="padding-right: 4rem; cursor:pointer"
                                        >
                                            <div class="ml2 font-w1x b font13 text-capitalize" style="font-size:13px;">Total current assets</div>
                                        </td>
                                        <td class="pv3 pr4 bb b--black-20 tl items-center textRight backgroundF3">
                                            {{ formatAmount(currentAssets?.totalAssets, $store.state.Settings?.currency) }}
                                        </td>
                                    </tr>
                                    <!-- fixed asset -->
                                    <tr class="font-w1 box-border-bottom">
                                        <td
                                            @click="toggleSubTable('fixedAsset')"
                                            class="pv3 bb b--black-20 tl font-w1 flex items-center"
                                            style="padding-right: 4rem; cursor:pointer"
                                        >
                                            <img
                                                id=""
                                                class="showLayers"
                                                :class="fixedAsset ? 'rotate' : ''"
                                                :src="require('@/assets/images/arrow.png')"
                                                width="5"
                                            />
                                            <div class="ml2 text-capitalize" style="font-size:13px;">Fixed Asset</div>
                                        </td>
                                        <td class="pv3 pr4 bb b--black-20 tl items-center"></td>
                                    </tr>
                                    <tr v-if="fixedAsset" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl3 bb b--black-20 font13 text-capitalize" style="padding-top:15px; padding-bottom:15px;">
                                            Land
                                        </td>
                                        <td class="bb b--black-20 textRight pr4" style="padding-top:15px; padding-bottom:15px;">
                                            {{ formatAmount(0, $store.state.Settings?.currency) }}
                                        </td>
                                    </tr>
                                    <tr v-if="fixedAsset" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl3 bb b--black-20 font13 text-capitalize" style="padding-top:15px; padding-bottom:15px;">
                                            Machinery and Equipment
                                        </td>
                                        <td class="bb b--black-20 textRight pr4" style="padding-top:15px; padding-bottom:15px;">
                                            {{ formatAmount(0, $store.state.Settings?.currency) }}
                                        </td>
                                    </tr>
                                    <tr v-if="fixedAsset" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl3 bb b--black-20 font13 text-capitalize" style="padding-top:15px; padding-bottom:15px;">
                                            Inventories
                                        </td>
                                        <td class="bb b--black-20 textRight pr4" style="padding-top:15px; padding-bottom:15px;">
                                            {{ formatAmount(Vehicles, $store.state.Settings?.currency) }}
                                        </td>
                                    </tr>
                                    <tr v-if="fixedAsset" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl3 bb b--black-20 b font13 text-capitalize backgroundF3" style="padding-top:15px; padding-bottom:15px;">
                                            Total fixed assets
                                        </td>
                                        <td class="bb b--black-20 textRight pr4 backgroundF3" style="padding-top:15px; padding-bottom:15px;">
                                            {{ formatAmount(0, $store.state.Settings?.currency) }}
                                        </td>
                                    </tr>
                                    <tr style="padding-top:5px; padding-bottom:25px;">
                                        <td
                                            class="bb b--black-20 b text-capitalize font13 backgroundF3 pl3"
                                            style="padding-top:15px; padding-bottom:15px;"
                                        >
                                            Total Assets
                                        </td>
                                        <td class="bb b--black-20 b textRight pr4 backgroundF3" style="padding-top:15px; padding-bottom:15px;">
                                            {{ formatAmount(currentAssets?.totalAssets + 0, $store.state.Settings?.currency) }}
                                        </td>
                                    </tr>
                                    <!-- liabilities -->
                                    <tr class="font-w1 box-border-bottom">
                                        <td
                                            @click="toggleSubTable('liabili')"
                                            class="pv3 bb b--black-20 tl font-w1 flex items-center"
                                            style="padding-right: 4rem; cursor:pointer"
                                        >
                                            <img
                                                id=""
                                                class="showLayers"
                                                :class="liabili ? 'rotate' : ''"
                                                :src="require('@/assets/images/arrow.png')"
                                                width="5"
                                            />
                                            <div class="ml2 text-capitalize" style="font-size:13px;">Liabilities</div>
                                        </td>
                                        <td class="pv3 pr4 bb b--black-20 tl items-center"></td>
                                    </tr>
                                    <tr v-if="liabili" class="font-w1 box-border-bottom">
                                        <td
                                            @click="toggleSubTable('outstandingPay')"
                                            class="pv3 bb b--black-20 tl font-w1 flex items-center pl3"
                                            style="padding-right: 4rem; cursor:pointer"
                                        >
                                            <img
                                                id=""
                                                class="showLayers"
                                                :class="outstandingPay ? 'rotate' : ''"
                                                :src="require('@/assets/images/arrow.png')"
                                                width="5"
                                            />
                                            <div class="ml2 text-capitalize" style="font-size:13px;">Outstanding Payables</div>
                                        </td>
                                        <td class="pv3 pr4 bb b--black-20 tl items-center"></td>
                                    </tr>
                                    <tr v-if="outstandingPay" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl4 bb b--black-20 font13 text-capitalize" style="padding-top:15px; padding-bottom:15px;">
                                            Purchase credit
                                        </td>
                                        <td class="bb b--black-20 textRight pr4" style="padding-top:15px; padding-bottom:15px;">
                                            {{
                                                formatAmount(
                                                    liabilities?.outstandingPayables?.purchaseCredit,
                                                    $store.state.Settings?.currency
                                                )
                                            }}
                                        </td>
                                    </tr>
                                    <tr v-if="outstandingPay" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl4 bb b--black-20 font13 text-capitalize" style="padding-top:15px; padding-bottom:15px;">
                                            Borrowings
                                        </td>
                                        <td class="bb b--black-20 textRight pr4" style="padding-top:15px; padding-bottom:15px;">
                                            {{
                                                formatAmount(
                                                    liabilities?.outstandingPayables?.borrowings,
                                                    $store.state.Settings?.currency
                                                )
                                            }}
                                        </td>
                                    </tr>
                                    <tr v-if="outstandingPay" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl4 bb b--black-20 font13 text-capitalize" style="padding-top:15px; padding-bottom:15px;">
                                            Settlement/Repayment
                                        </td>
                                        <td class="bb b--black-20 textRight pr4" style="padding-top:15px; padding-bottom:15px;">
                                            ({{
                                                formatAmount(
                                                    liabilities?.outstandingPayables?.settlementAndRepayment,
                                                    $store.state.Settings?.currency
                                                )
                                            }})
                                        </td>
                                    </tr>
                                    <tr v-if="outstandingPay" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl4 bb b--black-20 b font13 text-capitalize backgroundF3" style="padding-top:15px; padding-bottom:15px;">
                                            Total
                                        </td>
                                        <td class="bb b--black-20 b textRight pr4 backgroundF3" style="padding-top:15px; padding-bottom:15px;">
                                            {{
                                                formatAmount(
                                                    liabilities?.outstandingPayables?.total,
                                                    $store.state.Settings?.currency
                                                )
                                            }}
                                        </td>
                                    </tr>
                                    <tr v-if="liabili" class="font-w1 box-border-bottom">
                                        <td
                                            @click="toggleSubTable('vat')"
                                            class="pv3 bb b--black-20 tl font-w1 flex items-center pl3"
                                            style="padding-right: 4rem; cursor:pointer"
                                        >
                                            <img
                                                id=""
                                                class="showLayers"
                                                :class="vat ? 'rotate' : ''"
                                                :src="require('@/assets/images/arrow.png')"
                                                width="5"
                                            />
                                            <div class="ml2 text-capitalize" style="font-size:13px;">VAT Payables</div>
                                        </td>
                                        <td class="pv3 pr4 bb b--black-20 tl items-center"></td>
                                    </tr>
                                    <tr v-if="vat" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl4 bb b--black-20 font13 text-capitalize" style="padding-top:15px; padding-bottom:15px;">
                                            Sales VAT
                                        </td>
                                        <td class="bb b--black-20 textRight pr4" style="padding-top:15px; padding-bottom:15px;">
                                            {{
                                                formatAmount(liabilities?.vatPayables?.salesVat, $store.state.Settings?.currency)
                                            }}
                                        </td>
                                    </tr>
                                    <tr v-if="vat" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl4 bb b--black-20 font13 text-capitalize" style="padding-top:15px; padding-bottom:15px;">
                                            VAT Received
                                        </td>
                                        <td class="bb b--black-20 textRight pr4" style="padding-top:15px; padding-bottom:15px;">
                                            {{
                                                formatAmount(
                                                    liabilities?.vatPayables?.vatReceived,
                                                    $store.state.Settings?.currency
                                                )
                                            }}
                                        </td>
                                    </tr>
                                    <tr v-if="vat" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl4 bb b--black-20 font13 text-capitalize" style="padding-top:15px; padding-bottom:15px;">
                                            VAT Remittance
                                        </td>
                                        <td class="bb b--black-20 textRight pr4" style="padding-top:15px; padding-bottom:15px;">
                                            {{
                                                formatAmount(
                                                    liabilities?.vatPayables?.vatRemittance,
                                                    $store.state.Settings?.currency
                                                )
                                            }}
                                        </td>
                                    </tr>
                                    <tr v-if="liabili" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl3 bb b--black-20 font13 text-capitalize" style="padding-top:15px; padding-bottom:15px;">
                                            Outstanding Customer advance
                                        </td>
                                        <td class="bb b--black-20 textRight pr4" style="padding-top:15px; padding-bottom:15px;">
                                            {{
                                                formatAmount(
                                                    liabilities?.outstandingCustomerAdvance,
                                                    $store.state.Settings?.currency
                                                )
                                            }}
                                        </td>
                                    </tr>
                                    <tr style="padding-top:5px; padding-bottom:25px;">
                                        <td class="bb b--black-20 b font13 text-capitalize backgroundF3 pl3" style="padding-top:15px; padding-bottom:15px;">
                                            Total Liability
                                        </td>
                                        <td class="bb b--black-20 b textRight pr4 backgroundF3" style="padding-top:15px; padding-bottom:15px;">
                                            {{ formatAmount(liabilities?.total, $store.state.Settings?.currency) }}
                                        </td>
                                    </tr>
                                    <tr class="font-w1 box-border-bottom">
                                        <td
                                            @click="toggleSubTable('theEquity')"
                                            class="pv3 bb b--black-20 tl font-w1 flex items-center"
                                            style="padding-right: 4rem; cursor:pointer"
                                        >
                                            <img
                                                id=""
                                                class="showLayers"
                                                :class="theEquity ? 'rotate' : ''"
                                                :src="require('@/assets/images/arrow.png')"
                                                width="5"
                                            />
                                            <div class="ml2 text-capitalize" style="font-size:13px;">Equity</div>
                                        </td>
                                        <td class="pv3 pr4 bb b--black-20 tl items-center"></td>
                                    </tr>
                                    <tr v-if="theEquity" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl3 bb b--black-20 font13 text-capitalize" style="padding-top:15px; padding-bottom:15px;">
                                            Capital
                                        </td>
                                        <td class="bb b--black-20 textRight pr4" style="padding-top:15px; padding-bottom:15px;">
                                            {{ formatAmount(equity?.capital, $store.state.Settings?.currency) }}
                                        </td>
                                    </tr>
                                    <tr v-if="theEquity" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl3 bb b--black-20 font13 text-capitalize" style="padding-top:15px; padding-bottom:15px;">
                                            Retained earnings
                                        </td>
                                        <td class="bb b--black-20 textRight pr4" style="padding-top:15px; padding-bottom:15px;">
                                            {{ formatAmount(equity?.retainedEarnings, $store.state.Settings?.currency) }}
                                        </td>
                                    </tr>
                                    <tr v-if="theEquity" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl3 bb b--black-20 font13 text-capitalize" style="padding-top:15px; padding-bottom:15px;">
                                            Current earnings
                                        </td>
                                        <td class="bb b--black-20 textRight pr4" style="padding-top:15px; padding-bottom:15px;">
                                            {{ formatAmount(equity?.currentEarnings, $store.state.Settings?.currency) }}
                                        </td>
                                    </tr>
                                    <tr v-if="theEquity" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl3 bb b--black-20 font13 text-capitalize" style="padding-top:15px; padding-bottom:15px;">
                                            Withdrawal
                                        </td>
                                        <td class="bb b--black-20 textRight pr4" style="padding-top:15px; padding-bottom:15px;">
                                            {{ formatAmount(equity?.withdrawal, $store.state.Settings?.currency) }}
                                        </td>
                                    </tr>
                                    <tr v-if="theEquity" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl3 bb b--black-20 font13 text-capitalize" style="padding-top:15px; padding-bottom:15px;">
                                            Total Equity
                                        </td>
                                        <td class="bb b--black-20 textRight pr4" style="padding-top:15px; padding-bottom:15px;">
                                            {{ formatAmount(equity?.totalEquity, $store.state.Settings?.currency) }}
                                        </td>
                                    </tr>
                                    <tr v-if="theEquity" style="padding-top:5px; padding-bottom:25px;">
                                        <td class="pl3 bb b--black-20 font13 text-capitalize" style="padding-top:15px; padding-bottom:15px;">
                                            Total Liability and Equity
                                        </td>
                                        <td class="bb b--black-20 textRight pr4" style="padding-top:15px; padding-bottom:15px;">
                                            {{
                                                formatAmount(
                                                    liabilities?.total + equity?.totalEquity,
                                                    $store.state.Settings?.currency
                                                )
                                            }}
                                        </td>
                                    </tr>
                                    <tr style="padding-top:5px; padding-bottom:25px;">
                                        <td class="bb b--black-20 b font13 text-capitalize backgroundF3 pl3" style="padding-top:15px; padding-bottom:15px;">
                                            Total Equity
                                        </td>
                                        <td class="bb b--black-20 b textRight pr4 backgroundF3" style="padding-top:15px; padding-bottom:15px;">
                                            {{ formatAmount(equity?.totalEquity, $store.state.Settings?.currency) }}
                                        </td>
                                    </tr>
                                    <tr style="padding-top:5px; padding-bottom:25px;">
                                        <td class="bb b--black-20 b font13 text-capitalize backgroundF3 pl3" style="padding-top:15px; padding-bottom:15px;">
                                            Total Liability and Equity
                                        </td>
                                        <td class="bb b--black-20 b textRight pr4 backgroundF3" style="padding-top:15px; padding-bottom:15px;">
                                            {{
                                                formatAmount(
                                                    liabilities?.total + equity?.totalEquity,
                                                    $store.state.Settings?.currency
                                                )
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="ph2 pt4">
                            <!-- <div class="pb3 flex justify-between b box-border-bottom">
                                <div>Accounts</div>
                                <div class="flex justify-between w-40"></div>
                            </div> -->

                            <!-- <div>
                                <div
                                    class="flex items-center pv3 box-border-bottom font-w1"
                                    @click="toggleSubTable('currAsset')"
                                    style="cursor:pointer"
                                >
                                    <img src="@/assets/images/arrow.png" alt="" width="5" :class="currAsset ? 'rotate' : ''" />
                                    <div class="pl2 text-capitalize">Current Assets</div>
                                </div>
                                <div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom" v-if="currAsset">
                                        <div class="font-w1">Cash on hand</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{ formatAmount(currentAssets?.cashOnHand, $store.state.Settings?.currency) }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom" v-if="currAsset">
                                        <div class="font-w1">Cash at Bank</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{ formatAmount(currentAssets?.cashAtBank, $store.state.Settings?.currency) }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom" v-if="currAsset">
                                        <div class="font-w1">Inventories</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{ formatAmount(currentAssets?.inventories, $store.state.Settings?.currency) }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between items-center pv3 pl3  box-border-bottom" v-if="currAsset">
                                        <div
                                            class="flex items-center"
                                            @click="toggleSubTable('outstandingRec')"
                                            style="cursor:pointer"
                                        >
                                            <img
                                                src="@/assets/images/arrow.png"
                                                alt=""
                                                width="5"
                                                :class="outstandingRec ? 'rotate' : ''"
                                            />
                                            <div class="pl2 text-capitalize">Outstanding Receivables</div>
                                        </div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(
                                                        currentAssets?.outstandingReceivables?.totalOutstandingReceivables,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom" v-if="outstandingRec">
                                        <div class="font-w1">Sales Credit</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(
                                                        currentAssets?.outstandingReceivables?.salesCredit,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom" v-if="outstandingRec">
                                        <div class="font-w1">Loans Given Out</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(
                                                        currentAssets?.outstandingReceivables?.loanGivenOut,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom" v-if="outstandingRec">
                                        <div class="font-w1">Settlement/Repayment</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                ({{
                                                    formatAmount(
                                                        currentAssets?.outstandingReceivables?.settlementAndRepayment,
                                                        $store.state.Settings?.currency
                                                    )
                                                }})
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom" v-if="outstandingRec">
                                        <div>Bad Debt</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                ({{
                                                    formatAmount(
                                                        currentAssets?.outstandingReceivables?.badDebt,
                                                        $store.state.Settings?.currency
                                                    )
                                                }})
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl3 box-border-bottom" v-if="currAsset">
                                        <div class="font-w1">Prepayment</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(
                                                        currentAssets?.prepayment?.total,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-between pv3 pl2 b box-border-bottom">
                                        <div class="font-w1x">Total current assets</div>
                                        <div class="flex justify-between w-40-l pl2 tr">
                                            <div class="db-l dnx">
                                                {{ formatAmount(currentAssets?.totalAssets, $store.state.Settings?.currency) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <!-- <div>
                                <div
                                    class="flex items-center pv3 box-border-bottom font-w1"
                                    @click="toggleSubTable('fixedAsset')"
                                    style="cursor:pointer"
                                >
                                    <img src="@/assets/images/arrow.png" alt="" width="5" :class="fixedAsset ? 'rotate' : ''" />
                                    <div class="pl2 text-capitalize">Fixed Assets</div>
                                </div>
                                <div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom" v-if="fixedAsset">
                                        <div class="font-w1">Land</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">{{ formatAmount(0, $store.state.Settings?.currency) }}</div>
\                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom" v-if="fixedAsset">
                                        <div class="font-w1">Machinery and Equipment</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">{{ formatAmount(0, $store.state.Settings?.currency) }}</div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom" v-if="fixedAsset">
                                        <div class="font-w1">Inventories</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{ formatAmount(Vehicles, $store.state.Settings?.currency) }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-between pv3 pl2 b box-border-bottom">
                                        <div class="font-w1x">Total fixed assets</div>
                                        <div class="flex justify-between w-40-l pl2 tr">
                                            <div class="db-l dnx">{{ formatAmount(0, $store.state.Settings?.currency) }}</div>
                                        </div>
                                    </div>

                                    <div class="flex justify-between pv3 pl2 b box-border-bottom">
                                        <div class="font-w1x text-capitalize">Total Assets</div>
                                        <div class="flex justify-between w-40-l pl2 tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(currentAssets?.totalAssets + 0, $store.state.Settings?.currency)
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div
                                    class="flex items-center pv3 font-w1 box-border-bottom"
                                    @click="toggleSubTable('liabili')"
                                    style="cursor:pointer"
                                >
                                    <img src="@/assets/images/arrow.png" alt="" width="5" :class="liabili ? 'rotate' : ''" />
                                    <div class="pl2 text-capitalize">Liabilities</div>
                                </div>
                                <div v-if="liabili">
                                    <div
                                        class="pv3 pl2 flex items-center font-w1 box-border-bottom"
                                        @click="toggleSubTable('outstandingPay')"
                                        style="cursor:pointer"
                                    >
                                        <img
                                            src="@/assets/images/arrow.png"
                                            alt=""
                                            width="5"
                                            :class="outstandingPay ? 'rotate' : ''"
                                        />
                                        <div class="pl2 text-capitalize">Outstanding Payables</div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom" v-if="outstandingPay">
                                        <div>Purchase credit</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(
                                                        liabilities?.outstandingPayables?.purchaseCredit,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom" v-if="outstandingPay">
                                        <div>Borrowings</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(
                                                        liabilities?.outstandingPayables?.borrowings,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom" v-if="outstandingPay">
                                        <div>Settlement/Repayment</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                ({{
                                                    formatAmount(
                                                        liabilities?.outstandingPayables?.settlementAndRepayment,
                                                        $store.state.Settings?.currency
                                                    )
                                                }})
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 b box-border-bottom">
                                        <div>Total</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(
                                                        liabilities?.outstandingPayables?.total,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between items-center pv3 pl3  box-border-bottom">
                                        <div class="flex item-center" @click="toggleSubTable('vat')" style="cursor:pointer">
                                            <img
                                                src="@/assets/images/arrow.png"
                                                alt=""
                                                class="imgarrow"
                                                :class="vat ? 'rotate' : ''"
                                            />
                                            <div class="pl2 text-capitalize">VAT Payables</div>
                                        </div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(liabilities?.vatPayables?.total, $store.state.Settings?.currency)
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom" v-if="vat">
                                        <div>Sales VAT</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(
                                                        liabilities?.vatPayables?.salesVat,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom" v-if="vat">
                                        <div>VAT Received</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(
                                                        liabilities?.vatPayables?.vatReceived,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl4 box-border-bottom" v-if="vat">
                                        <div>VAT Remittance</div>
                                        <div class="flex justify-between w-40-l tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(
                                                        liabilities?.vatPayables?.vatRemittance,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl2 box-border-bottom font-w1" v-if="vat">
                                        <div>Outstanding Customer advance</div>
                                        <div class="flex justify-between w-40-l pl2 tr">
                                            <div class="db-l dnx">
                                                {{
                                                    formatAmount(
                                                        liabilities?.outstandingCustomerAdvance,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between pv3 pl2 box-border-bottom font-w1">
                                        <div>Total liability</div>
                                        <div class="flex justify-between w-40-l pl2 tr">
                                            <div class="db-l dnx">
                                                {{ formatAmount(liabilities?.total, $store.state.Settings?.currency) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div
                                    class="flex items-center pv3 font-w1 box-border-bottom"
                                    @click="toggleSubTable('theEquity')"
                                    style="cursor:pointer"
                                >
                                    <img src="@/assets/images/arrow.png" alt="" width="5" :class="theEquity ? 'rotate' : ''" />
                                    <div class="pl2 text-capitalize">Equity</div>
                                </div>
                                <div class="flex justify-between pv3 pl4 box-border-bottom" v-if="theEquity">
                                    <div>Capital</div>
                                    <div class="flex justify-between w-40-l tr">
                                        <div class="db-l dnx">
                                            {{ formatAmount(equity?.capital, $store.state.Settings?.currency) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="flex justify-between pv3 pl4 box-border-bottom" v-if="theEquity">
                                    <div>Retained earnings</div>
                                    <div class="flex justify-between w-40-l tr">
                                        <div class="db-l dnx">
                                            {{ formatAmount(equity?.retainedEarnings, $store.state.Settings?.currency) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="flex justify-between pv3 pl4 box-border-bottom" v-if="theEquity">
                                    <div>Current earnings</div>
                                    <div class="flex justify-between w-40-l tr">
                                        <div class="db-l dnx">
                                            {{ formatAmount(equity?.currentEarnings, $store.state.Settings?.currency) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="flex justify-between pv3 pl4 box-border-bottom" v-if="theEquity">
                                    <div>Withdrawal</div>
                                    <div class="flex justify-between w-40-l tr">
                                        <div class="db-l dnx">
                                            {{ formatAmount(equity?.withdrawal, $store.state.Settings?.currency) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="flex justify-between pv3 pl2 b font-w1 box-border-bottom">
                                    <div>Total Equity</div>
                                    <div class="flex justify-between w-40-l pl2 tr">
                                        <div class="db-l dnx">
                                            {{ formatAmount(equity?.totalEquity, $store.state.Settings?.currency) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="flex justify-between pv3 pl2 box-border-bottom b">
                                    <div class="text-capitalize">Total Liability and Equity</div>
                                    <div class="flex justify-between w-40-l pl2 tr">
                                        <div class="db-l dnx">
                                            {{
                                                formatAmount(
                                                    liabilities?.total + equity?.totalEquity,
                                                    $store.state.Settings?.currency
                                                )
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <!-- <div class="flex justify-between pv4">
                                <div></div>
                                <div class="w-auto-l w-100 flex">
                                    <button class="flex items-center">
                                        Save as &nbsp; <img src="../../assets/images/down.svg" alt="" />
                                    </button>
                                    <button class="flex items-center ml2">
                                        Export &nbsp; <img src="../../assets/images/down.svg" alt="" />
                                    </button>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </app-wrapper>
</template>

<script>
import { computed, reactive, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import AppWrapper from '@/layout/AppWrapper'
import { formatAmount, formatDateFilter } from '@/utils/lib'
import OrgData from '@/components/OrgData'
import Spinner from '@/components/Spinner'

export default {
    name: 'BalanceSheet',
    components: { AppWrapper, OrgData, Spinner },

    setup() {
        const store = useStore()
        const orgData = computed(() => store?.state?.Auth?.userData)
        const currentAssets = computed(() => store?.state?.Reporting?.currentAssets)
        const liabilities = computed(() => store?.state?.Reporting?.liabilities)
        const equity = computed(() => store?.state?.Reporting?.equity)
        const isLoading = computed(() => store?.state?.Reporting?.isLoading)
        const currAsset = ref(false) //changed ref to true so it displays on default
        const outstandingRec = ref(false)
        const fixedAsset = ref(false)
        const liabili = ref(false) //changed ref to true so it displays on default
        const outstandingPay = ref(false)
        const vat = ref(false)
        const theEquity = ref(false)

        const month = new Date().getMonth()
        const year = new Date().getFullYear()
        const day = new Date().getDate()
        const lastYear = year - 1

        const defaultStartDate_1 = new Date(year, 0, 1)
        const defaultEndDate_1 = new Date(year, month, day)

        const defaultStartDate_2 = new Date(lastYear, 0, 1)
        const defaultEndDate_2 = new Date(lastYear, month, day)

        const state = reactive({
            startDate1: defaultStartDate_1,
            endDate1: defaultEndDate_1,
            startDate2: defaultStartDate_2,
            endDate2: defaultEndDate_2,
            comparison: 'period1'
        })

        const handleFilter = () => {
            if (state.startDate1 > state.endDate1 || state.startDate2 > state.endDate2) {
                return store.dispatch('Alert/setAlert', {
                    message: 'Start date cannot be greater than end date',
                    status: false
                })
            }

            if (!state.startDate1 || !state.endDate1 || !state.startDate2 || !state.endDate2) {
                return store.dispatch('Alert/setAlert', {
                    message: 'Please make sure all dates are selected in both periods',
                    status: false
                })
            }
        }

        const toggleSubTable = name => {
            if (name === 'currAsset') {
                currAsset.value = !currAsset.value
                outstandingRec.value = false
               
            }
            if (name === 'outstandingRec') {
                outstandingRec.value = !outstandingRec.value
            }
            if (name === 'fixedAsset') {
                fixedAsset.value = !fixedAsset.value
            }
            if (name === 'liabili') {
                liabili.value = !liabili.value
                outstandingPay.value = false
                vat.value = false
            }
            if (name === 'outstandingPay') {
                outstandingPay.value = !outstandingPay.value
            }
            if (name === 'vat') {
                vat.value = !vat.value
            }
            if (name === 'theEquity') {
                theEquity.value = !theEquity.value
            }
        }

        onMounted(() => {
            state.startDate1 = formatDateFilter(state.startDate1)
            state.endDate1 = formatDateFilter(state.endDate1)
            state.startDate2 = formatDateFilter(state.startDate2)
            state.endDate2 = formatDateFilter(state.endDate2)
            const payload = {
                startDate1: state.startDate1,
                endDate1: state.endDate1,
                startDate2: state.startDate2,
                endDate2: state.endDate2
            }
            store.dispatch('Reporting/getReportingCurrentAssets', payload)
            store.dispatch('Reporting/getReportingLiabilities', payload)
            store.dispatch('Reporting/getReportingEquity', payload)
        })

        return {
            state,
            orgData,
            handleFilter,
            formatAmount,
            currentAssets,
            liabilities,
            equity,
            isLoading,
            currAsset,
            outstandingRec,
            fixedAsset,
            liabili,
            outstandingPay,
            vat,
            theEquity,
            toggleSubTable
        }
    }
}
</script>

<style scoped>
.rotate {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.imgarrow {
    width: 5px;
    height: 9px;
    margin-top: 3px;
}

.bgTop {
    background: white;
    width: 100%;
}

.unZIndex {
    z-index: -1 !important;
}
.font13{
    font-size: 13px;
}
.textRight{
    text-align: right;
}
.backgroundF3{
    background-color: #f3f3f3
}
</style>
